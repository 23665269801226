import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"
import SupportForm from "../forms/supportForm"

function Support() {
  return (
    <Layout>
      <Seo title="Support" />
      <InnerHero title="Support" />
      <section className="pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="view-all-header--borderless">
                <h2 className="title">Find support for your game</h2>
              </div>
            </div>
          </div>
          <div className="row mt-50 mb-none-30">
            <div className="col-lg-4 mb-30">
              <Link className="game-small-card" to="/support-detail">
                <div className="game-small-card__thumb">
                  <img src="/games/nosurrender-game-logo.png" alt="The War" />
                </div>
                <div className="game-small-card__content">
                  <h6>No Surrender</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-120 pb-120 position-relative overflow-hidden">
        <div className="contact-left-el">
          <img src="/elements/contact-left-el.png" alt="no surrender" />
        </div>
        <div className="contact-right-el">
          <img src="/elements/contact-right-el.png" alt="no surrender" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="/elements/header-el.png" alt="no surrender" />
                </div>
                <h2 className="section-title">Get in Touch</h2>
                <p>
                  If you have a question or general enquiry, we&rsquo;re here to
                  help. Complete the form below and we will get back to you as
                  soon as we can.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="support-form-wrapper">
                <h3 className="mb-3">Contact Us / Bug Report Form</h3>
                <SupportForm />
                {/* <form className="form--primary" id="supportForm">
                  <div className="form-group">
                    <label>Your Full Name</label>
                    <input
                      className="form-control style--two"
                      id="name"
                      type="text"
                      name="full_name"
                      placeholder="Enter Your Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Your Email</label>
                    <input
                      className="form-control style--two"
                      id="email"
                      type="text"
                      name="email"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="form-group form-group-radio">
                    <label>How do you feel?</label>
                    <div className="custom-radio">
                      <div className="custom-radio-item">
                        <input
                          className="form-control"
                          id="feel-smile"
                          type="radio"
                          name="feel"
                          value="smile"
                        />
                        <label for="feel-smile" title="Smile">
                          <i className="fas fa-smile"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                      </div>
                      <div className="custom-radio-item">
                        <input
                          className="form-control"
                          id="feel-meh"
                          type="radio"
                          name="feel"
                          value="meh"
                        />
                        <label for="feel-meh" title="Meh">
                          <i className="fas fa-meh"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                      </div>
                      <div className="custom-radio-item">
                        <input
                          className="form-control"
                          id="feel-frown"
                          type="radio"
                          name="feel"
                          value="frown"
                        />
                        <label for="feel-frown" title="Frown">
                          <i className="fas fa-frown"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                      </div>
                    </div>
                    <div id="custom-radio-error"></div>
                  </div>
                  <div className="form-group nice-select-group">
                    <label>Subject</label>
                    <select className="nice-select" name="subject">
                      <option
                        value=""
                        data-display="--- Please choose an option ---"
                      >
                        --- Please choose an option ---
                      </option>
                      <option value="Technical support">
                        Technical Support
                      </option>
                      <option value="Billing question or issue">
                        Billing question or issue
                      </option>
                      <option value="Game installation trouble">
                        Game installation trouble
                      </option>
                      <option value="Question about how to play">
                        Question about how to play
                      </option>
                      <option value="General Feedback">General feedback</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="form-group nice-select-group">
                    <label>Game</label>
                    <select className="nice-select" name="game">
                      <option
                        value=""
                        data-display="--- Please choose an option ---"
                      >
                        --- Please choose an option ---
                      </option>
                      <option value="the-war">The War</option>
                      <option value="the-sniper">The Sniper</option>
                    </select>
                  </div>
                  <div className="form-group nice-select-group">
                    <label>Platform</label>
                    <select className="nice-select" name="platform">
                      <option
                        value=""
                        data-display="--- Please choose an option ---"
                      >
                        --- Please choose an option ---
                      </option>
                      <option value="iOS">Apple iPad or iPhone</option>
                      <option value="Android">Android</option>
                      <option value="WP8">Windows Phone 8</option>
                      <option value="PC">PC/Mac</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Feedback</label>
                    <textarea
                      className="form-control style--two"
                      id="feedback"
                      placeholder="Enter Your Feedback"
                      name="feedback"
                    ></textarea>
                  </div>
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#extraInformation"
                    aria-expanded="false"
                    aria-controls="extraInformation"
                  >
                    <i className="fas fa-plus-square mr-2"></i>Extra Information
                  </button>
                  <div className="collapse" id="extraInformation">
                    <div className="mt-4">
                      <div className="form-group">
                        <label>Device Model</label>
                        <input
                          className="form-control style--two"
                          id="deviceModel"
                          type="text"
                          name="device_model"
                          placeholder="Enter Device Model"
                        />
                      </div>
                      <div className="form-group">
                        <label>OS Version</label>
                        <input
                          className="form-control style--two"
                          id="osVersion"
                          type="text"
                          name="os_version"
                          placeholder="Enter OS Version"
                        />
                      </div>
                      <div className="form-group">
                        <label>App Version</label>
                        <input
                          className="form-control style--two"
                          id="appVersion"
                          type="text"
                          name="app_version"
                          placeholder="Enter App Version"
                        />
                      </div>
                      <div className="form-group">
                        <label>User ID</label>
                        <input
                          className="form-control style--two"
                          id="userId"
                          type="text"
                          name="user_id"
                          placeholder="Enter User ID"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button className="submit-btn" type="submit">
                      Send
                    </button>
                  </div>
                  <span className="mr-1">
                    This site is protected by reCAPTCHA and the Google{" "}
                  </span>
                  <a className="mr-1" href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>
                  <span className="mr-1">and</span>
                  <a className="mr-1" href="https://policies.google.com/terms">
                    Terms of Service
                  </a>
                  <span>apply.</span>
                </form> */}
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 mt-lg-0 mt-5">
              <div className="contact-content-wrapper">
                <h2>Have questions?</h2>
                <p>
                  Have questions about payments or price plans? We have answers!
                </p>
                <div className="row mt-50 mb-none-30">
                  <div className="col-lg-12 mb-30">
                    <div className="contact-item">
                      <div className="contact-item__icon">
                        <i className="fas fa-envelope fa-sm"></i>
                      </div>
                      <div className="contact-item__content">
                        <h3 className="title">Email Us</h3>
                        <a href="mailto:community@nosurrender.studio">
                          community@nosurrender.studio
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Support
